<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Články - Typy - Slide - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
          label="Název"
          :validate-status="error('ale_name') ? 'error' : ''"
          :help="error('ale_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['ale_name', {rules: [{max: 60, message: 'Název nemůže být delší než 100 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <my-form-image-item
          :image="thumbImage"
          :image-next-count="thumbImageNextCount"
          :images="thumbImages"
          :on-image-switch-change="onThumbImageSwitchChange"
          :on-next-images-click="onNextThumbImagesClick"
          :form="form"
          :required="true"
          :change-image="changeImage"
          type="numeric"
        >
          <span slot="title">
            Obrázky&nbsp;
            <a-tooltip title="Vyber si obrázek pro jednotlivé slidy.">
              <a-icon type="question-circle-o"/>
            </a-tooltip>
          </span>
        </my-form-image-item>

        <a-form-item
          label="Slidy"
          :required="true"
        >
          <a-row
            v-for="(k, index) in form.getFieldValue('items')"
            :key="index">
            <a-col :offset="1" :span="21">
              <a-form-item
                label="Pozadí"
                :required="true"
              >
                <a-col :xl="4" :md="6" :sm="8" :xs="12">
                  <div v-if="thumbImage.selected[index] !== undefined" :class="[$style.item, $style.withControls]">
                    <img class="img-fluid img-size-6" v-lazy="$uploadImagePath + thumbImage.selected[index].iae_path + getImageSize(thumbImage.selected[index], 6) + '.' + thumbImage.selected[index].iae_format">
                    <div :class="$style.itemControls">
                      <span :class="$style['item-name']">{{ toImageName(thumbImage.selected[index].iae_name) }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <div :class="[$style.item]">
                      <a-icon theme="twoTone" twoToneColor="#ccc" type="exclamation-circle" />
                    </div>
                    <small class="d-block ml-3">Vyber si obrázek pro tento slide!</small>
                  </div>
                </a-col>
              </a-form-item>

              <a-form-item
                label="Nadpis kurzivou"
              >
                <a-input v-decorator="[`title_thin[${index}]`]"
                         placeholder="Nadpis kurzivou"
                />
              </a-form-item>

              <a-form-item
                label="Nadpis tučně"
              >
                <a-input v-decorator="[`title_bold[${index}]`]"
                         placeholder="Nadpis tučně"
                />
              </a-form-item>

              <a-form-item
                label="Popisek"
              >
                <a-input v-decorator="[`description[${index}]`]"
                         placeholder="Popisek"
                         :autoSize="{ minRows: 4, maxRows: 10 }"
                         type="textarea"
                />
              </a-form-item>

              <a-form-item
                label="Text hlavního tlačítka"
              >
                <a-input v-decorator="[`button_main_title[${index}]`, {rules: [{required: form.getFieldValue('button_main_link[' + index + ']') !== undefined && form.getFieldValue('button_main_link[' + index +']').length > 0, message: 'Text hlavního tlačítka musí být vyplněn!'}]}]"
                         placeholder="Text hlavního tlačítka"
                />
              </a-form-item>

              <a-form-item
                label="Odkaz hlavního tlačítka"
              >
                <a-input v-decorator="[`button_main_link[${index}]`, {rules: [{required: form.getFieldValue('button_main_title[' + index + ']') !== undefined && form.getFieldValue('button_main_title[' + index +']').length > 0, message: 'Odkaz hlavního tlačítka musí být vyplněn!'}]}]"
                         placeholder="Odkaz hlavního tlačítka"
                />
              </a-form-item>

              <a-form-item
                label="Text sekundárního tlačítka"
              >
                <a-input v-decorator="[`button_secondary_title[${index}]`, {rules: [{required: form.getFieldValue('button_secondary_link[' + index + ']') !== undefined && form.getFieldValue('button_secondary_link[' + index +']').length > 0, message: 'Text sekundárního tlačítka musí být vyplněn!'}]}]"
                         placeholder="Text sekundárního tlačítka"
                />
              </a-form-item>

              <a-form-item
                label="Odkaz sekundárního tlačítka"
              >
                <a-input v-decorator="[`button_secondary_link[${index}]`, {rules: [{required: form.getFieldValue('button_secondary_title[' + index + ']') !== undefined && form.getFieldValue('button_secondary_title[' + index +']').length > 0, message: 'Odkaz sekundárního tlačítka musí být vyplněn!'}]}]"
                         placeholder="Odkaz sekundárního tlačítka"
                />
              </a-form-item>

              <a-form-item
                label="Pozice textu"
              >
                <a-switch checkedChildren="Vpravo" unCheckedChildren="Vlevo" v-decorator="[`position[${index}]`, { valuePropName: 'checked' }]" />
              </a-form-item>
            </a-col>
            <a-col :span="2">
              <a-icon
                v-if="form.getFieldValue('items').length > 1"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="form.getFieldValue('items').length === 1"
                @click="() => removeItem(index)"
              />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <a-button
            class="m-auto d-block"
            type="dashed"
            @click="addItem"
            style="width: 200px;"
          >
            <a-icon type="plus" /> Přidat další
          </a-button>
        </a-form-item>

        <a-form-item label="Zveřejnit?">
          <a-checkbox v-decorator="['ale_is_active', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <a-form-item label="Přidat mezeru pod článek?">
          <a-checkbox v-decorator="['gap', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || verifyImages() || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'
import imageMixins from '@/components/MyComponents/Gallery/image-mixins'

export default {
  components: {
    ActionTools, LanguageTab, myFormImageItem,
  },
  mixins: [imageSizeMixin, thumbImageMixin, imageMixins],
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('items', { initialValue: [], preserve: true })
  },
  data() {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/article/type/sle',
          title: 'Seznam',
        },
      ],
      item: {
        languages: [],
      },
      formItemLayout: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    verifyImages() {
      let result = false
      this.thumbImage.selected.some(value => {
        if (value === undefined) {
          result = true
          return true
        }
      })
      return result
    },
    changeImage(image, destinationIndex) {
      const index = destinationIndex - 1
      this.thumbImage.selected[index] = image
      this.form.setFieldsValue({
        items: this.form.getFieldValue('items').concat({ button_secondary_title: undefined, button_secondary_link: undefined, button_main_title: undefined, button_main_link: undefined, position: false, title_thin: undefined, title_bold: undefined, image: undefined, description: undefined }),
      })
      const items = this.form.getFieldValue('items')
      items.pop()
      this.form.setFieldsValue({
        items: items,
      })
    },
    removeItem(index) {
      const items = this.form.getFieldValue('items')
      if (items.length === 1) {
        return
      }
      const titles = this.form.getFieldValue('title')
      const descriptions = this.form.getFieldValue('description')
      items.splice(index, 1)
      titles.splice(index, 1)
      descriptions.splice(index, 1)
      this.thumbImage.selected.splice(index, 1)
      this.form.setFieldsValue({
        items: items,
        title: titles,
        description: descriptions,
      })
      this.$nextTick(() => {
        this.form.validateFields()
      })
    },
    addItem() {
      this.thumbImage.selected.push(undefined)
      this.form.setFields({
        items: {
          value: this.form.getFieldValue('items').concat({
            button_secondary_title: undefined, button_secondary_link: undefined, button_main_title: undefined, button_main_link: undefined, position: false, title_thin: undefined, title_bold: undefined, image: undefined, description: undefined,
          }),
        },
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('article/post', this.getData())
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        ate_type: 'SLE',
        languages: this.item.languages.filter(x => x.ale_name !== undefined && x.ale_name.trim().length > 0).map(x => {
          const content = {
            gap: x.ale_content.gap,
            items: x.ale_content.items.map(y => {
              return {
                button_main_title: y.button_main_link === undefined ? null : y.button_main_title,
                button_main_link: y.button_main_link === undefined ? null : y.button_main_link,
                button_secondary_title: y.button_secondary_title === undefined ? null : y.button_secondary_title,
                button_secondary_link: y.button_secondary_link === undefined ? null : y.button_secondary_link,
                description: y.description === undefined ? null : y.description,
                image: y.image,
                position: y.position,
                title_bold: y.title_bold === undefined ? null : y.title_bold,
                title_thin: y.title_thin === undefined ? null : y.title_thin,
              }
            }),
          }
          return {
            lge_id: x.lge_id,
            ale_title_thin: null,
            ale_content: content,
            ale_description: null,
            ale_title: null,
            ale_name: x.ale_name !== undefined ? x.ale_name : null,
            ale_is_active: x.ale_is_active,
          }
        }),
      }
    },
    handleChange(oldLanguage) {
      const foundObj = { article: false }
      const newObj = {
        article: {
          lge_id: oldLanguage,
          ale_content: {
            gap: this.form.getFieldValue('gap'),
            items: this.form.getFieldValue('items').map((x, y) => {
              return {
                position: this.form.getFieldValue('position[' + y + ']') ? 'right' : 'left',
                image: this.thumbImage.selected[y],
                button_secondary_title: this.form.getFieldValue('button_secondary_title[' + y + ']'),
                button_secondary_link: this.form.getFieldValue('button_secondary_link[' + y + ']'),
                button_main_title: this.form.getFieldValue('button_main_title[' + y + ']'),
                button_main_link: this.form.getFieldValue('button_main_link[' + y + ']'),
                title_thin: this.form.getFieldValue('title_thin[' + y + ']'),
                title_bold: this.form.getFieldValue('title_bold[' + y + ']'),
                description: this.form.getFieldValue('description[' + y + ']'),
              }
            }),
          },
          ale_name: this.form.getFieldValue('ale_name'),
          ale_is_active: this.form.getFieldValue('ale_is_active'),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.article
          foundObj.article = true
          return true
        }
      })
      if (!foundObj.article) {
        this.item.languages.push(newObj.article)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
  created() {
    if (this.form.getFieldValue('items').length === 0) {
      this.addItem()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = { article: this.item.languages.find(x => x.lge_id === newValue) }
        let newObj
        if (foundObj.article === undefined) {
          newObj = {
            ale_name: undefined,
            ale_is_active: true,
            gap: true,
          }
          if (this.form.getFieldValue('items').length === 0) {
            this.addItem()
          }
          this.thumbImage.selected = []
        } else {
          newObj = {
            gap: foundObj.article.ale_content.gap,
            ale_name: foundObj.article.ale_name,
            ale_is_active: foundObj.article.ale_is_active,
            items: foundObj.article.ale_content.items,
          }
          this.thumbImage.selected = []
          foundObj.article.ale_content.items.forEach((value, key) => {
            this.thumbImage.selected.push(value.image)
            this.form.getFieldDecorator(`button_secondary_title[${key}]`)
            this.form.getFieldDecorator(`button_secondary_link[${key}]`)
            this.form.getFieldDecorator(`button_main_title[${key}]`)
            this.form.getFieldDecorator(`button_main_link[${key}]`)
            this.form.getFieldDecorator(`title_thin[${key}]`)
            this.form.getFieldDecorator(`title_bold[${key}]`)
            this.form.getFieldDecorator(`position[${key}]`)
            this.form.getFieldDecorator(`description[${key}]`)
            this.form.setFields({
              ['position[' + key + ']']: { value: value.position === 'right' },
              ['button_secondary_title[' + key + ']']: { value: value.button_secondary_title, errors: (value.button_secondary_title === undefined || value.button_secondary_title.length === 0) && value.button_secondary_link !== undefined && value.button_secondary_link.trim().length > 0 ? ['Text sekundárního tlačítka musí být vyplněn!'] : undefined },
              ['button_secondary_link[' + key + ']']: { value: value.button_secondary_link, errors: (value.button_secondary_link === undefined || value.button_secondary_link.length === 0) && value.button_secondary_title !== undefined && value.button_secondary_title.trim().length > 0 ? ['Odkaz sekundárního tlačítka musí být vyplněn!'] : undefined },
              ['button_main_title[' + key + ']']: { value: value.button_main_title, errors: (value.button_main_title === undefined || value.button_main_title.length === 0) && value.button_main_link !== undefined && value.button_main_link.trim().length > 0 ? ['Text hlavního tlačítka musí být vyplněn!'] : undefined },
              ['button_main_link[' + key + ']']: { value: value.button_main_link, errors: (value.button_main_link === undefined || value.button_main_link.length === 0) && value.button_main_title !== undefined && value.button_main_title.trim().length > 0 ? ['Odkaz hlavního tlačítka musí být vyplněn!'] : undefined },
              ['title_thin[' + key + ']']: { value: value.title_thin },
              ['title_bold[' + key + ']']: { value: value.title_bold },
              ['description[' + key + ']']: { value: value.description },
            })
          })
        }
        this.form.setFieldsValue(newObj)
        this.thumbImage.trigger = !this.thumbImage.trigger
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
